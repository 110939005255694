import { useState } from 'react';
import BurgerIcon from './BurgerIcon';

function Navbar() {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);

  return (
    <div>
      <nav className="relative navbar bg-transparent border border-solid border-r-0 border-l-0 border-t-0 border-[#676767]">
        <div className="navbar-start">
          <div
            onClick={() => window.location.assign('/')}
            className="btn btn-ghost text-white font-bold text-[20px] md:text-[25px] uppercase"
          >
            <img
              loading="lazy"
              src="/logo.svg"
              className="w-[140px]"
              alt="Sell-Well logo"
            />
          </div>
        </div>
        <div className="navbar-end flex lg:hidden flex-col justify-center items-end">
          <BurgerIcon
            isToggled={isBurgerToggled}
            onClick={() => setIsBurgerToggled(!isBurgerToggled)}
          />
        </div>
        {isBurgerToggled && (
          <div className="absolute top-[120%] left-0 flex flex-col gap-2 items-start p-2 backdrop-blur-md w-[90%] rounded-[24px]">
            <div
              className="btn btn-ghost text-white font-[300] text-[16px] uppercase"
              onClick={() => window.location.assign('/')}
            >
              Strona główna
            </div>
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost flex flex-row flex-nowrap gap-2 items-center"
              >
                <div className="text-white font-[300] text-[16px] uppercase">
                  Oferta
                </div>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.06066 8.51348C7.47487 9.09927 6.52513 9.09927 5.93934 8.51348L2.10355 4.6777C2.05719 4.63133 2.05719 4.55617 2.10355 4.5098C2.14992 4.46344 2.22508 4.46344 2.27145 4.5098L5.23223 7.47059C6.20855 8.4469 7.79146 8.4469 8.76777 7.47059L11.7286 4.5098C11.7749 4.46344 11.8501 4.46344 11.8964 4.5098C11.9428 4.55617 11.9428 4.63133 11.8964 4.6777L12.25 5.03125L11.8964 4.6777L8.06066 8.51348Z"
                    stroke="white"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-40 md:w-52"
              >
                <li>
                  <div onClick={() => window.location.assign('/sales')}>
                    Sprzedaż
                  </div>
                </li>
                <li>
                  <div onClick={() => window.location.assign('/buy')}>
                    Kupno
                  </div>
                </li>
                <li>
                  <div onClick={() => window.location.assign('/lease')}>
                    Wynajem
                  </div>
                </li>
                <li>
                  <div onClick={() => window.location.assign('/invest')}>
                    Inwestycje
                  </div>
                </li>
              </ul>
            </div>
            <button
              onClick={() =>
                (
                  document.getElementById(
                    'basic-modal',
                  ) as HTMLDialogElement | null
                )?.showModal()
              }
              className="cursor-pointer uppercase text-[16px] text-white font-semibold flex flex-row justify-between items-center bg-[#32A771] outline-none border-none rounded-[63px] py-2 px-4 w-[180px]"
            >
              <div>Kontakt</div>
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="19.5" cy="19.5" r="19.5" fill="white" />
                <path
                  d="M19.9216 16.1716C21.4837 17.7337 21.4837 20.2663 19.9216 21.8284L16.75 25C16.4739 25.2761 16.0261 25.2761 15.75 25C15.4739 24.7239 15.4739 24.2761 15.75 24L18 21.75C19.5188 20.2312 19.5188 17.7688 18 16.25L15.75 14C15.4739 13.7239 15.4739 13.2761 15.75 13C16.0261 12.7239 16.4739 12.7239 16.75 13L19.9216 16.1716Z"
                  fill="#32A771"
                />
              </svg>
            </button>
          </div>
        )}
        <div className="navbar-end hidden lg:flex flex-row md:gap-2 items-center">
          <div
            className="btn btn-ghost text-white font-[300] text-[16px] uppercase"
            onClick={() => window.location.assign('/')}
          >
            Strona główna
          </div>
          <div className="dropdown">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost flex flex-row flex-nowrap gap-2 items-center"
            >
              <div className="text-white font-[300] text-[16px] uppercase">
                Oferta
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.06066 8.51348C7.47487 9.09927 6.52513 9.09927 5.93934 8.51348L2.10355 4.6777C2.05719 4.63133 2.05719 4.55617 2.10355 4.5098C2.14992 4.46344 2.22508 4.46344 2.27145 4.5098L5.23223 7.47059C6.20855 8.4469 7.79146 8.4469 8.76777 7.47059L11.7286 4.5098C11.7749 4.46344 11.8501 4.46344 11.8964 4.5098C11.9428 4.55617 11.9428 4.63133 11.8964 4.6777L12.25 5.03125L11.8964 4.6777L8.06066 8.51348Z"
                  stroke="white"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-40 md:w-52"
            >
              <li>
                <div onClick={() => window.location.assign('/sales')}>
                  Sprzedaż
                </div>
              </li>
              <li>
                <div onClick={() => window.location.assign('/buy')}>Kupno</div>
              </li>
              <li>
                <div onClick={() => window.location.assign('/lease')}>
                  Wynajem
                </div>
              </li>
              <li>
                <div onClick={() => window.location.assign('/invest')}>
                  Inwestycje
                </div>
              </li>
            </ul>
          </div>
          <button
            onClick={() =>
              (
                document.getElementById(
                  'basic-modal',
                ) as HTMLDialogElement | null
              )?.showModal()
            }
            className="cursor-pointer uppercase text-[16px] text-white font-semibold flex flex-row justify-between items-center bg-[#32A771] outline-none border-none rounded-[63px] py-2 px-4 w-[180px]"
          >
            <div>Kontakt</div>
            <svg
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19.5" cy="19.5" r="19.5" fill="white" />
              <path
                d="M19.9216 16.1716C21.4837 17.7337 21.4837 20.2663 19.9216 21.8284L16.75 25C16.4739 25.2761 16.0261 25.2761 15.75 25C15.4739 24.7239 15.4739 24.2761 15.75 24L18 21.75C19.5188 20.2312 19.5188 17.7688 18 16.25L15.75 14C15.4739 13.7239 15.4739 13.2761 15.75 13C16.0261 12.7239 16.4739 12.7239 16.75 13L19.9216 16.1716Z"
                fill="#32A771"
              />
            </svg>
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
