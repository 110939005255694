import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import Navbar from './Navbar';

export default function HeroSection(props: {
  title: JSX.Element;
  desc: string;
  image: string;
  hasCard?: boolean;
  noButton?: boolean;
}) {
  return (
    <section
      className={`flex flex-col justify-between ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage(props.image, {
        withDarkOverlay: true,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Navbar />
      <div className="flex flex-col items-start xl:flex-row xl:items-end justify-between gap-8 pt-24 pb-12">
        <div className="flex flex-col gap-8">
          <div className="text-white text-[30px] md:text-[45px] xl:text-[55px] xl:w-[70%]">
            {props.title}
          </div>
          <div className="text-white text-[16px] xl:w-[60%] leading-6">
            {props.desc}
          </div>
          {!props.noButton && (
            <button
              onClick={() =>
                (
                  document.getElementById(
                    'basic-modal',
                  ) as HTMLDialogElement | null
                )?.showModal()
              }
              className="cursor-pointer uppercase text-[16px] text-white font-semibold flex flex-row justify-between items-center bg-[#32A771] outline-none border-none rounded-[63px] py-4 px-4 max-w-[470px]"
            >
              <div>Odbierz bezpłatną poradę</div>
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="19.5" cy="19.5" r="19.5" fill="white" />
                <path
                  d="M19.9216 16.1716C21.4837 17.7337 21.4837 20.2663 19.9216 21.8284L16.75 25C16.4739 25.2761 16.0261 25.2761 15.75 25C15.4739 24.7239 15.4739 24.2761 15.75 24L18 21.75C19.5188 20.2312 19.5188 17.7688 18 16.25L15.75 14C15.4739 13.7239 15.4739 13.2761 15.75 13C16.0261 12.7239 16.4739 12.7239 16.75 13L19.9216 16.1716Z"
                  fill="#32A771"
                />
              </svg>
            </button>
          )}
        </div>
        {props.hasCard && (
          <div className="self-center rounded-[53px] flex flex-col gap-6 backdrop-blur-lg p-8 min-w-[300px] xl:min-w-[364px]">
            <div className="flex flex-col gap-4">
              <div className="text-[#32A771] text-[25px] font-bold uppercase">
                26 dni
              </div>
              <div className="text-white text-[16px]">
                Średni czas sprzedaży
              </div>
            </div>
            <div className="h-[1px] w-full bg-[#646464]" />
            <div className="flex flex-col gap-4">
              <div className="text-[#32A771] text-[25px] font-bold uppercase">
                2003
              </div>
              <div className="text-white text-[16px]">
                Rok rejestracji firmy
              </div>
            </div>
            <div className="h-[1px] w-full bg-[#646464]" />
            <div className="flex flex-col gap-4">
              <div className="text-[#32A771] text-[25px] font-bold uppercase">
                100%
              </div>
              <div className="text-white text-[16px]">
                Zadowolonych klientów
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
