import { defaultStyles } from '@/utils/defaultStyles';
import ContactForm from './ContactForm';

export default function ContactFormSection(props: {
  title?: JSX.Element;
  desc?: JSX.Element;
}) {
  return (
    <section
      className={`flex flex-col xl:flex-row justify-between gap-8 py-12 md:py-24 ${defaultStyles.paddingBig}`}
      id="contact"
    >
      <div className="flex flex-col gap-4 xl:w-[40%]">
        {props.title ?? (
          <div className="text-[35px] xl:text-[45px] text-black uppercase">
            <span className="font-bold">
              100% gwarancji, że płatność za nasze usługi nastąpi
            </span>{' '}
            po pomyślnym zakończeniu transakcji
          </div>
        )}
        {props.desc ?? (
          <div className="text-[16px] text-[#959595]">
            Skorzystaj z bezpłatnej 30-minutowej porady i odkryj, jak sprzedać
            swoją nieruchomość łatwo, uzyskując maksymalną wartość.
          </div>
        )}
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <div className="text-[16px] text-black mt-4">Kontakt</div>
            <div className="w-full h-[1px] bg-[#E0E0E0]" />
            <div className="flex flex-row gap-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_98_2910)">
                  <path
                    d="M1.21413 9.56642C4.00717 15.6532 8.9771 20.4925 15.1559 23.116L16.1464 23.5576C18.4124 24.5679 21.0764 23.8004 22.4585 21.7391L23.7528 19.8087C24.1736 19.1811 24.0455 18.3357 23.4577 17.8612L19.068 14.3178C18.4232 13.7973 17.4752 13.9187 16.982 14.5845L15.6241 16.4181C12.1396 14.6981 9.31056 11.8676 7.59176 8.38105L9.4241 7.02232C10.0897 6.5288 10.2108 5.58015 9.69066 4.93503L6.14929 0.542722C5.67511 -0.0453809 4.83053 -0.173667 4.20329 0.247154L2.26075 1.55044C0.187766 2.94121 -0.575031 5.62864 0.457903 7.90205L1.21299 9.56396L1.21413 9.56642Z"
                    fill="#161616"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_98_2910">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div className="text-[16px] text-black">530-923-663</div>
            </div>
            <div className="flex flex-row gap-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_98_2915)">
                  <path
                    d="M12 15.6C10.0152 15.6 8.4 13.9848 8.4 12C8.4 10.0152 10.0152 8.4 12 8.4C13.9848 8.4 15.6 10.0152 15.6 12C15.6 13.9848 13.9848 15.6 12 15.6ZM12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C12.3182 24 12.6235 23.8735 12.8485 23.6485C13.0735 23.4235 13.2 23.1182 13.2 22.8C13.2 22.4818 13.0735 22.1765 12.8485 21.9515C12.6235 21.7265 12.3182 21.6 12 21.6C6.7068 21.6 2.4 17.2932 2.4 12C2.4 6.7068 6.7068 2.4 12 2.4C17.2932 2.4 21.6 6.7068 21.6 12V13.2C21.6 14.1924 20.7924 15 19.8 15C18.8076 15 18 14.1924 18 13.2V12C18 8.6916 15.3084 6 12 6C8.6916 6 6 8.6916 6 12C6 15.3084 8.6916 18 12 18C13.8372 18 15.4644 17.1516 16.566 15.846C16.9555 16.3292 17.448 16.7196 18.0074 16.9884C18.5669 17.2572 19.1792 17.3978 19.8 17.4C22.116 17.4 24 15.516 24 13.2V12C24 5.3832 18.6168 0 12 0Z"
                    fill="#161616"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_98_2915">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div className="text-[16px] text-black">
                Biuro@sellwell-nieruchomosci.pl
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </section>
  );
}
